.homePage {
  .collegeSection {
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    background-color: rgb(19, 19, 50);
    div {
      padding: 5.5rem 0;
      margin: 0px 3.4rem;
      max-width: 102.4rem;
      text-align: center;
      animation-name: collegeSection;
      animation-duration: 3s;
      transform: translate3d(0%, 0%, 0);
      p {
        font-size: 1.2rem;
        margin-bottom: 2.4rem;
        font-family: 'Playfair Display', serif;
        font-weight: 400;
        letter-spacing: 1.8px;
        line-height: 120%;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .collegeSection {
    p {
      font-size: 0.8rem !important;
      margin-bottom: 0.4rem !important;
      font-family: 'Playfair Display', serif !important;
      font-weight: 400 !important;
      letter-spacing: 0.6px !important;
      line-height: 120% !important;
    }
  }
}

@media screen and (max-width: 450px) {
  .collegeSection {
    div {
      padding: 3rem 0.1rem !important;
      margin: 0 0.4rem !important;
    }
    div::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      background: url('../../assets/icons/logo.png') no-repeat center center fixed;
      background-size: 60%;
      opacity: 0.4;
      z-index: -1;
    }
    p {
      margin: 1rem 0 !important;
      font-size: 0.9rem !important;
      font-family: 'Playfair Display', serif !important;
      font-weight: 500 !important;
      letter-spacing: 0.6px !important;
      line-height: 120% !important;
    }
  }
}

@keyframes collegeSection {
  from {
    opacity: 0;
    scale: 0;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}
