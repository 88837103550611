.menu {
  background: transparent;
  border: none;
  cursor: pointer;
  display: none;
  svg {
    width: 35px;
    height: 35px;
    color: white;
  }
}

.szh-menu {
  margin: 0 !important;
  padding: 0 !important;
  background-color: rgb(255, 255, 255) !important;
  width: 100vh !important;
  height: 100vh;
  z-index: 50;
  select {
    display: none;
    color: rgb(21, 21, 65) !important;
    position: absolute !important;
    bottom: 22% !important;
    right: 55% !important;
  }
  &-container {
    ul {
      list-style-type: none;
      a {
        text-decoration: none;
        color: #706e6a;
        li {
          font-family: 'Noto Sans Armenian', sans-serif;
          font-weight: 400;
          letter-spacing: 1px;
          border-bottom: 1px solid #e8e7e7;
          text-align: start;
          padding: 25px 0;
          padding-left: 30px;
        }
      }
    }
  }
}

