.slides {
  width: 100%;
  height: 120vh;
  max-height: 900px;
}

.slider {
  background-size: cover;
  background-position: center;
  background-color: white;
  width: 100%;
  height: 100%;
  position: relative;
  h2 {
    position: absolute;
    bottom: 30%;
    right: 6%;
    font-size: 3.9rem;
    padding: 0.5rem 2rem;
    background-color: rgba(19, 19, 50, 0.563);
    color: white;
    border-radius: 1rem;
    font-family: 'Noto Sans Armenian', sans-serif;
    font-weight: 900;
    -webkit-animation-duration: 0.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: banner3header;
    animation-delay: 1.5s;
    letter-spacing: 0.5rem;
  }
  button {
    position: absolute;
    bottom: 27%;
    right: 6%;
    background-color: rgba(19, 19, 50);
    border-radius: 0.5rem;
    padding: 10px 40px;
    text-align: center;
    animation-delay: 2.5s;
    animation-duration: 1s;
    transform: translate3d(0, 100%, 0);
    animation-name: banner1title;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border: none;
    a {
      color: aliceblue;
      font-family: 'Noto Sans Armenian', sans-serif;
      font-weight: 400;
      text-decoration: none;
      font-size: 2.5rem !important;
      line-height: 140% !important;
      letter-spacing: 0.04rem !important;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: black !important;
  width: 3.2rem !important;
  height: 5rem !important;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  scale: 0.8;
  transition-duration: 1s;
}

@keyframes banner3header {
  from {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes banner1title {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 100%, 0);
  }
}

@media screen and (max-width: 950px) {
  .slides {
    height: 45vh !important;
    h2 {
      bottom: 15%;
      font-size: 1.2rem !important;
      letter-spacing: 0.15rem !important;
    }
    button {
      padding: 5px 20px;
      bottom: 12%;
      a {
        font-size: 1rem !important;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .slides {
    height: 61vh !important;
    h2 {
      bottom: 15%;
      font-size: 1.2rem !important;
      letter-spacing: 0.15rem !important;
    }
    button {
      padding: 5px 20px;
      bottom: 12%;
      a {
        font-size: 1rem !important;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
    color: black !important;
    width: 1.2rem !important;
    height: 2rem !important;
  }
}
