@import url('https://fonts.googleapis.com/css2?family=Davel+Aghvor&display=swap');

.professionsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  gap: 2rem;

  h1 {
    color: rgb(19, 19, 50);
    font-family: 'Montserrat', sans-serif;
    font-size: 3.5rem;
    font-weight: 500;
  }
  .professions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
    .profession {
      position: relative;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 325px;
      height: 400px;
      gap: 20px;
      border-radius: 0.7rem;
      overflow: hidden;
      cursor: pointer;
      &-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, hsla(0, 0%, 85%, 0), #0f0535);
        // gap: 10px;
        h4 {
          font-family: 'Davel Aghvor', sans-serif !important;
          font-weight: 200;
          font-size: 1.5rem;
          text-align: center;
          color: white;
          animation-name: headingProfession;
          animation-duration: 2s;
        }
        a {
          font-family: 'Montserrat', sans-serif;
          font-weight: 200;
          font-size: 1rem;
          text-align: center;
          color: white;
          animation-name: headingProfession;
          animation-duration: 2s;
        }
      }
    }
  }
  @keyframes headingProfession {
    from {
      opacity: 0;
      transform: translate3d(0%, 200%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
  }

  @keyframes professionPic {
    from {
      opacity: 0;
      transform: translate3d(-60%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
  }

  @keyframes professionButton {
    from {
      opacity: 0;
      transform: translate3d(0%, 0%, -10%);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
  }
}

@media screen and (max-width: 1200px) {
  .profession {
    width: 39% !important;
  }
}

@media screen and (max-width: 950px) {
  .professionsSection {
    padding: 5rem 0 !important;
    h1 {
      font-size: 2rem !important;
    }
  }

  .profession {
    width: 90% !important;
    h4 {
      font-size: 1.7rem !important;
    }
  }
}

@media screen and (max-width: 550px) {
  .professionsSection {
    padding: 2rem 0 !important;
    background-color: white !important;
    h1 {
      font-size: 2rem !important;
    }
  }
}