.admission-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgb(237, 239, 247);
  padding: 20px;
  gap: 40px;
  padding-bottom: 100px;
  h1 {
    color: rgb(19, 19, 50);
    font-weight: 200;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.4rem;
    font-size: 4rem;
  }
  span {
    color: rgb(19, 19, 50);
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    font-size: 1.5rem;
    max-width: 800px;
    text-align: center;
  }
}

.form {
  background: linear-gradient(
    to bottom,
    rgba(5, 10, 40, 0.9),
    rgba(15, 30, 70, 0.8),
    rgba(10, 25, 60, 0.7)
  );
  box-shadow:
    0px 30px 25px rgba(10, 20, 50, 0.8),
    rgba(20, 40, 80, 0.85) 0px 30px 60px -12px inset,
    rgba(15, 35, 70, 0.7) 0px 18px 36px -18px inset;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 100%;
  max-width: 800px;

  .field {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 10px !important;
      font-weight: bold;
      color: #ffffff;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
    }

    input[type='email'],
    input[type='text'],
    input[type='tel'] {
      padding-left: 5px !important;
      font-family: 'Montserrat', sans-serif;
      width: 100%;
      padding: 10px 0;
      font-size: 16px;
      color: #fff;
      border: none;
      border-bottom: 1px solid #fff;
      outline: none;
      border-radius: 0 !important;
      background: transparent;
    }

    select {
      height: 40px;
      width: 100%;
      border-radius: 0%;
      background-color: rgb(222, 226, 245);
      color: rgb(19, 19, 50);
    }

    input[type='file'] {
      height: 50px !important;
      display: block;
      margin-top: 10px;
      border: none;
      outline: none;
      padding: 5px;
      color: white;
    }
    input:focus {
      outline: none;
    }
    .file,
    .select {
      margin-bottom: 20px !important;
      width: 100% !important;
    }
  }

  button {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #0056b3;
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  .error {
    color: red;
    margin-top: 5px;
  }
}

@media screen and (max-width: 550px) {
  .admission-form-container {
    h1 {
      font-size: 2.2rem !important;
    }
    span {
      font-size: 1.1rem;
    }
    .error {
      color: red;
      font-size: 0.8rem !important;
    }
  }
}

.toast-position {
  top: 74px !important;
}