.professions {
  &-slide {
    height: 100vh;
    background-image: url('../../assets/pictures/slidesPic/ProfessionsSlide.jpg');
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      color: white;
      position: absolute;
      bottom: 20%;
      right: 10%;
      font-size: 4.9rem;
      font-family: 'Noto Sans Armenian', sans-serif;
      font-weight: 500;
      -webkit-animation-duration: 0.5s;
      animation-duration: 1.5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      animation-name: banner3header;
      animation-delay: 1.5s;
      border-radius: 1rem;
      padding: 0.5rem 2rem;
      background-color: rgba(51, 127, 171, 0.693);
    }
  }
  &-descriptions {
    max-width: 1500px;
    padding: 2.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10rem;
    margin: auto;
    &-profession {
      display: flex;
      justify-content: space-between;
      width: 80%;
      overflow: hidden;
      background-color: white;
      border-radius: 2rem;
      --tw-shadow: 0 20px 25px -5px rgba(9, 33, 85, 0.5), 0 8px 10px -6px rgba(9, 33, 85, 0.3);
      --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color, rgba(9, 33, 85, 0.5)),
        0 8px 10px -6px var(--tw-shadow-color, rgba(9, 33, 85, 0.3));
      box-shadow:
        0 0 #0000,
        0 0 #0000,
        var(--tw-shadow);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
      &-pic {
        width: 50%;
        display: flex;
        align-items: center;
        img {
          height: 60%;
          width: 90%;
        }
      }
      button {
        background-color: rgb(19, 19, 50);
        border-radius: 1rem;
        padding: 0.5rem 1rem;
        color: white;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 0.2rem;
      }
      &-desc {
        width: 50%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          letter-spacing: 0.2rem;
          font-weight: 800 !important;
          font-family: 'Montserrat', sans-serif;
          color: rgb(19, 19, 50);
        }
        span {
          padding: 2rem;
          // font-weight: 200 !important;
          font-family: 'Montserrat', sans-serif;
          color: rgb(19, 19, 50);
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .professions {
    &-slide {
      height: 95vh;
    }
  }
}
@media screen and (max-width: 650px) {
  .professions {
    &-slide {
      height: 60vh !important;
      h1 {
        font-size: 1.2rem !important;
        letter-spacing: 0.15rem !important;
        bottom: 8% !important;
        right: 1% !important;
      }
    }
    .professions-descriptions-profession{
      border-radius: 10px !important;
    }
  }
}
@media screen and (max-width: 1300px) {
  .professions {
    &-slide {
      height: 35vh;
      h1 {
        font-size: 1.5rem !important;
        bottom: 4% !important;
      }
    }
    &-descriptions {
      display: flex;
      gap: 3rem;
      &-profession {
        flex-direction: column-reverse !important;
        &-pic {
          width: 100%;
          img {
            width: 100% !important;
          }
        }
        &-desc {
          width: 100% !important;
          padding: 2rem 0;
          h1 {
            font-size: 1.2rem !important;
            text-align: center;
          }
        }
      }
    }
  }
}
